<template>
  <div style="position: absolute;top: 0;width: 100%;height: 100%;background: #F0F2F5;">
    <pcHeaders></pcHeaders>

    <div class="cen">
      <div class="w">
        <div class="headBox">
          <div class="leftBox">
            <img v-if="courseInfo.cimage !== '' && courseInfo.cimage !==null"
                 style="width: 253px;height: 206px;"
                 :src="courseInfo.cimage"
                 @error="handleError"
                 alt="">
            <div v-else
                 style="position: relative;display: flex;justify-content: center;align-items: center;width: 253px;height: 206px;">
              <img style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;;"
                   src="../../../assets/indexImg/noimg.png"
                   alt="">
              <div style="position: relative;z-index: 11;font-size: 15px;font-family: kaiti;text-align: center;padding: 0 10px;font-weight: bold;color: #355FD9;">
                {{ courseInfo.cname}}
              </div>
            </div>
          </div>
          <div class="rightBox">
            <div style="font-size: 18px;">{{ courseInfo.cname }}</div>
            <div class="mt10"
                 style="color: #FF6C4C;font-size: 16px;">{{ courseInfo.codeName }} &emsp; ￥{{ courseInfo.cprice }} &emsp; {{ courseInfo.ccredithour }}学时</div>
            <div class="mt10">培训方式 : {{ courseInfo.cmode == '01' ? '在线' : courseInfo.cmode == '02' ? '面授':courseInfo.cmode == '03' ? '混合' :'外派'}} </div>
            <div class="mt10 textOver"
                 :title="courseInfo.cdesc">课程介绍 :{{ courseInfo.cdesc }}</div>

            <div class="goumBtn"
                 @click="purchase"
                 v-if="classInfo.classEnrolStatus == '02' && classInfo.timeFlag == '02'">购买</div>

            <div class="mt10">学习时间:{{ courseInfo.cstarttime }} - {{ courseInfo.cendtime }}</div>
          </div>
        </div>

        <div class="courseList">
          <div class="courseHead">
            <div><img style="margin-top: 5px"
                   src="../../../assets/images/02_03.png"
                   alt=""></div>
            <div style="margin-left: 5px;">课程章节</div>
          </div>

          <div class="courseTable">
            <div class="leftBox">
              <el-table :data="courseList"
                        height="318"
                        style="width: 100%">
                <el-table-column label="课程名称"
                                 width="380"
                                 align="center">
                  <template slot-scope="scope">
                    <span style="color: #427DC3;">第{{ scope.$index+1 }}节：</span>{{ scope.row.CW_NAME }}
                  </template>
                </el-table-column>
                <el-table-column prop="CREDITNUM"
                                 label="学时"
                                 align="center">
                </el-table-column>
                <el-table-column label="操作"
                                 align="center">
                  <template slot-scope="scope">
                    <div v-if="courseInfo.isAudition == '02' && scope.$index == 0"
                         style="display: flex;justify-content: center;">
                      <div style="color: #FF9F53;cursor: pointer;"
                           @click="shiTi(scope.row)">试听</div>

                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="videoBox"
                 v-if="courseInfo.isAudition == '02'" style="position: relative;">
                 <img style="position: absolute;top: 10px;right:30px;z-index: 99999;"
               src="../../../assets/indexImg/video_new1.png"
               alt="">
              <video id="videoPlayer"
                     class="video-js videoBox"
                     muted></video>
            </div>
            <div class="videoBox"
                 v-else>
              <img class="videoBox"
                   src="../../../assets/images/def-st.jpg"
                   alt="">
            </div>
          </div>

        </div>
      </div>
    </div>

    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../../component/head.vue';
import pcBottom from '../../component/bottom.vue';
import { getCourseDetail } from '@/api/lsitFs.js';
import { doShoppingCartZG } from '@/api/car.js';
import Videojs from 'video.js';
import 'videojs-contrib-hls';
import '@videojs/http-streaming';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      courseInfo: {}, //班级数据
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      options: {
        autoplay: true, // 设置自动播放
        muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
        preload: 'auto', // 预加载
        controls: true // 显示播放的控件
      },
      total: 0,
      defaultImageUrl: require('../../../assets/images/noimg.png'),
      courseList: [], //课程列表
      player: null,
      classInfo: {} //班级数据
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    handleError(event) {
      event.target.src = this.defaultImageUrl;
    },
    //购买
    purchase() {
      //非整体报名
      if (this.classInfo.isClassApply !== '02') {
        this.$router.push({
          path: '/purchase',
          query: {
            id: this.classInfo.classId
          }
        });
      } else {
        //整体报名直接加入购物车
        doShoppingCartZG({ classId: this.classInfo.classId }).then(res => {
          if (res.data.data.code == '1') {
            this.$message({
              message: '添加购物车成功！',
              type: 'success'
            });
            this.$router.push('/car');
          } else {
            this.$message({
              message: res.data.data.msg,
              type: 'warning'
            });
          }
        });
        return false;
      }
    },
    getVideo(videoUrl) {
      this.player = Videojs('videoPlayer', this.options);
      if (videoUrl.isZjw == '1' || videoUrl.isYlx == '1') {
        let url = videoUrl.LAUNCH;
        this.player.src(url);
      } else {
        if (/\.m3u8$/.test(videoUrl.LAUNCH)) {
          //判断视频源是否是m3u8的格式
          this.player.src([
            {
              src: videoUrl.LAUNCH,
              type: 'application/x-mpegURL' // 告诉videojs,这是一个hls流
            }
          ]);
        } else {
          let url = videoUrl.LAUNCH + '.mp4';
          this.player.src(url);
        }
      }
      let _this = this;
      this.player.on('loadstart', function () {
        console.log('开始请求数据 ');
      });
      this.player.on('progress', function () {
        console.log('正在请求数据 ');
      });
      this.player.on('loadedmetadata', function () {
        console.log('获取资源长度完成 ');
      });
      this.player.on('canplaythrough', function () {
        console.log('视频源数据加载完成');
      });
      this.player.on('waiting', function () {
        console.log('等待数据');
      });
      this.player.on('play', function () {
        console.log('视频开始播放');
      });
      this.player.on('playing', function () {
        console.log('视频播放中');
      });
      this.player.on('pause', function () {
        console.log('视频暂停播放');
      });
      this.player.on('ended', function () {
        console.log('视频播放结束');
      });
      this.player.on('error', function () {
        console.log('加载错误');
      });
      this.player.on('seeking', function () {
        console.log('视频跳转中');
      });
      this.player.on('seeked', function () {
        console.log('视频跳转结束');
      });
      this.player.on('ratechange', function () {
        console.log('播放速率改变');
      });
      this.player.on('timeupdate', function (e) {
        console.log('播放时长改变', _this.player.currentTime());
        if (_this.player.currentTime() >= 300) {
          _this.$message('试听5分钟结束，请购买完整课程进行学习！');
          _this.player.currentTime(0);
          return false;
        }
      });
      this.player.on('volumechange', function () {
        console.log('音量改变');
      });
      this.player.on('stalled', function () {
        console.log('网速异常');
      });
    },
    getCourseDetail() {
      getCourseDetail({ courseId: this.queryParams.courseId, classId: this.queryParams.classId }).then(res => {
        console.log(res.data);
        this.classInfo.classId = res.data.data.classId;
        this.classInfo.classEnrolStatus = res.data.data.classEnrolStatus;
        this.classInfo.isClassApply = res.data.data.isClassApply;
        this.courseList = res.data.data.cwUrlList;
        this.$nextTick(() => {
          this.getVideo(res.data.data.cwUrlList[0]);
        });
        this.courseInfo = res.data.data.course;
      });
    },
    shiTi(row) {
      this.getVideo(row);
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.queryParams.courseId = this.$route.query.courseId;
    this.queryParams.classId = this.$route.query.classId;
    this.classInfo.timeFlag = this.$route.query.timeFlag;
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getCourseDetail();
    // this.getVideo( this.courseList[0].LAUNCH)
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeDestroy() {
    if (this.player) {
      this.player.dispose(); //重置videojs的内部状态并移除dom
    }
  }
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.cen {
  font-family: Regular;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  background: #f0f2f5;
  .w {
    width: 1200px;
    padding-top: 30px;
    background: #f0f2f5;
    .headBox {
      background: #fff;
      padding: 30px 20px 10px 20px;
      border: 1px solid #f2f2f3;
      overflow: hidden;
      position: relative;
      display: flex;
      .rightBox {
        margin-left: 25px;
        font-size: 12px;
        .mt10 {
          margin-top: 10px;
        }
        .textOver {
          width: 800px;
          display: -webkit-box; /* Safari */
          -webkit-line-clamp: 2; /* Safari and Chrome */
          -webkit-box-orient: vertical; /* Safari and Chrome */
          overflow: hidden;
        }
        .goumBtn {
          width: 150px;
          height: 31px;
          text-align: center;
          line-height: 31px;
          background: #ff9f53;
          color: #fff;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          cursor: pointer;
          margin-left: 10px;
          margin-right: 10px;
          margin-top: 10px;
        }
      }
    }
    .courseList {
      margin-top: 25px;
      .courseHead {
        display: flex;
        font-size: 20px;
      }
      .courseTable {
        margin-top: 25px;
        display: flex;
        width: 100%;
        .leftBox {
          width: 600px;
        }
        .videoBox {
          width: 600px;
          position: relative;
          height: 318px;
        }
      }
    }
  }
}
</style>